// Import Stimulus and the necessary helpers
// import StimulusHmr from 'vite-plugin-stimulus-hmr'
import { Application, ControllerConstructor } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

Turbo.start();

// Turbo.setProgressBarDelay(100);

// Create the Stimulus application
const application = Application.start();

document.addEventListener("turbo:load", () => {
  // 現在のURLを取得
  const currentURL = window.location.href;

  // URLからqueryパラメータを抽出
  const urlSearchParams = new URLSearchParams(currentURL);

  // 特定のqueryパラメータ（"modal"）を削除
  if (urlSearchParams.has("modal")) {
    urlSearchParams.delete("modal");

    // 新しいURLを生成（queryパラメータ "modal" を削除）
    const newURL = window.location.pathname + "?" + urlSearchParams.toString();

    // 新しいURLに切り替え
    window.history.replaceState({}, "", newURL);
  }
});

document.addEventListener("turbo:submit-start", function () {
  const html = document.querySelector("html");
  if (html != null) {
    html.ariaBusy = "true";
  }
});

document.addEventListener("turbo:submit-end", function () {
  const html = document.querySelector("html");
  if (html != null) {
    html.ariaBusy = "false";
  }
});

window.onload = function () {
  if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
    document.body.addEventListener("touchstart", function () {}, false);
  }
};

const componentControllers = import.meta.glob(
  "../../../app/components/**/*_controller.{js,ts}",
  { eager: true },
);

const stimulusControllers = import.meta.glob(
  "../stimulus/**/*_controller.{js,ts}",
  { eager: true },
);

Object.entries(componentControllers).forEach(([path, module]) => {
  const controllerName = path
    .split("/")
    .slice(3)
    .join("--")
    .replace(/_controller\.[tj]s$/, "")
    .replace(/_/g, "-");
  if ("default" in module) {
    application.register(
      controllerName,
      module.default as ControllerConstructor,
    );
  }
});

Object.entries(stimulusControllers).forEach(([path, module]) => {
  const controllerName = path
    .split("/")
    .slice(2)
    .join("--")
    .replace(/_controller\.[tj]s$/, "")
    .replace(/_/g, "-");
  if ("default" in module) {
    //console.log(controllerName)
    application.register(
      controllerName,
      module.default as ControllerConstructor,
    );
  }
});

document.addEventListener("turbo:submit-end", (event: Event) => {
  const turboLocation: string = (
    event as CustomEvent
  ).detail.fetchResponse.response.headers.get("x-turbo-location");
  if (turboLocation !== null) {
    Turbo.visit(turboLocation, { action: "advance" });
  }
});

/*
document.addEventListener("turbo:before-fetch-response", (event: any) => {
  const {url, redirected, status} = event.detail.fetchResponse.response;
  const element: any = document.querySelector('turbo-frame#main')
  // If the response is a redirect requesting the _top frame then replace the document.body
  // with the response
  if (redirected) {
    event.preventDefault()
    event.detail.fetchResponse.response.text().then((html: any) => {
      var responseHTML = new DOMParser().parseFromString(html, 'text/html');
      element.innerHTML = responseHTML.body
    })
    // Turbo.visit(url, { action: 'replace' })
  }
});
*/
