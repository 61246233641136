import liff from '@line/liff'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'text' ];
  static values = {
    liffId: String,
    auth: String
  }

  connect() {
    liff
      .init({
          liffId: this.liffIdValue, // Use own liffId
      })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login();
        }
        const accessToken = liff.getAccessToken();

        fetch(this.authValue, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        }).then((response) => response.json())
          .then((data) => {
            if(!data.auth_token) {
              liff.login().then(() => {
                const accessToken = liff.getAccessToken();
                const idToken = liff.getIDToken();
              }).catch((err) => {
                console.log(err);
              });
            }
            Turbo.visit('/mypage');
          })
      }).catch((err) => {
        liff.login();
      });
  }
}
