import { Controller } from "@hotwired/stimulus";
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ["loadmore"]

  options = {
    threshold: 1,
    elemnt: this.loadmoreTarget,
  }

  connect() {
    useIntersection(this, this.options)
  }

  appear(entry) {
    this.loadmoreTarget.click();
  }
}
