import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.querySelector('#detail_area').style.display = 'none';
    console.log("connect");
  }

  onChange(event) {
    const checked = document.querySelector('#options_その他:checked');
    if (checked) {
      document.querySelector('#detail_area').style.display = 'block';
    }
  }
}
