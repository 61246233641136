import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const open = localStorage.getItem('adminMenu') || 'on';
    document.getElementById('adminMenu').dataset.open = open
  }
  toggleMenu(event) {
    const open = localStorage.getItem('adminMenu') || 'on';
    if (open == 'on') {
        localStorage.setItem('adminMenu', 'off')
        document.getElementById('adminMenu').dataset.open = 'off'
    } else {
        localStorage.setItem('adminMenu', 'on')
        document.getElementById('adminMenu').dataset.open = 'on'
    }
  }
}
