import { Controller } from "@hotwired/stimulus";
import throttle from 'just-throttle';

export default class extends Controller {
  static targets = ["industryArea", "categoryArea", "searchbar"];

  connect() {
    this.scroll = throttle(this.scroll, 15);
    window.addEventListener('scroll', this.scroll, { passive: true });
    this.scroll();
  }

  disconnect () {
    window.removeEventListener('scroll', this.scroll);
  }

  scroll () {
    const appbar = document.getElementById("search-app-bar")
    if (appbar) {
      appbar.dataset.active = window.scrollY > 250;
    }
  }

  closeSelect() {
    this.industryAreaTarget.dataset.active = false;
    this.categoryAreaTarget.dataset.active = false;
  }

  openIndustry() {
    this.industryAreaTarget.dataset.active = true;
    this.categoryAreaTarget.dataset.active = false;
  }

  openCategory() {
    this.industryAreaTarget.dataset.active = false;
    this.categoryAreaTarget.dataset.active = true;
  }
} 
