import { Controller } from "@hotwired/stimulus";

interface Station {
  name: string;
  suffix: string | null;
  id: string;
}

export default class extends Controller<HTMLSelectElement> {
  static targets = ["input", "result"];
  static values = {
    name: String,
    selected: String,
    debounceMs: { type: Number, default: 300 },
  };

  declare readonly selectedValue: string;
  declare readonly nameValue: string;
  declare readonly debounceMsValue: number;
  declare readonly inputTarget: HTMLInputElement;
  declare readonly resultTarget: HTMLDivElement;

  private debounceTimeout: number | null = null;

  connect() {
    this.search();
  }

  disconnect() {
    if (this.debounceTimeout) {
      window.clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }
  }

  changeInput() {
    this.debounceSearch();
  }

  private debounceSearch() {
    if (this.debounceTimeout) {
      window.clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = window.setTimeout(() => {
      this.search();
      this.debounceTimeout = null;
    }, this.debounceMsValue);
  }

  private search() {
    const query = this.inputTarget.value.trim();
    const url = `/api/stations/?q=${encodeURIComponent(query)}`;

    fetch(url)
      .then((response) => response.json())
      .then((json: Station[]) => {
        console.log("Received data:", json);
        this.renderHTML(json);

        // 初期選択状態の場合、バリデーション向けのchangeイベントを発火
        if (this.selectedValue) {
          const radioInput = this.resultTarget.querySelector(
            `input[value="${this.selectedValue}"]`,
          ) as HTMLInputElement;
          if (radioInput) {
            radioInput.dispatchEvent(new Event("change", { bubbles: true }));
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.renderHTML([]);
      });
  }

  renderHTML(stations: Station[]) {
    const html = stations
      .map(
        (station) => `
        <label class="choice">
          <input
            required="required"
            data-action="click->mypage--form--component#onChange"
            type="radio"
            value="${station.id}"
            name="${this.nameValue}"
            ${this.selectedValue == station.id ? "checked" : ""}
          >
          <span>${station.name}${station.suffix ? `<br><small class="small">${station.suffix}</small>` : ""}</span>
        </label>
      `,
      )
      .join("");
    this.resultTarget.innerHTML = html;
  }
}
