import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.className = `${this.element.dataset.baseclass} ${this.element.dataset.fadein}`;
    setTimeout(() => {
      this.element.className = `${this.element.dataset.baseclass} ${this.element.dataset.fadeout}`;
    
      setTimeout(()=>{
        this.element.remove();
      }, 1000);

    }, this.element.dataset.delay);
  }
}
