import { Controller } from "@hotwired/stimulus";

// Common email domains
const commonDomains: readonly string[] = [
  "yahoo.co.jp",
  "gmail.com",
  "outlook.jp",
  "outlook.com",
  "hotmail.com",
  "icloud.com",
  "docomo.ne.jp",
  "ezweb.ne.jp",
  "softbank.ne.jp",
  "i.softbank.jp",
  "ymobile.ne.jp",
  "uqmobile.jp",
  "ocn.ne.jp",
  "nifty.com",
  "biglobe.ne.jp",
  "so-net.ne.jp",
  "goo.jp",
  "mineo.jp",
  "rakuten.jp",
  "ybb.ne.jp",
  "mail.goo.ne.jp",
  "excite.co.jp",
  "livedoor.com",
  "line.me",
] as const;

// Email form controller class
export default class extends Controller {
  static targets = ["email", "error", "success"];

  declare readonly emailTarget: HTMLInputElement;
  declare readonly errorTarget: HTMLElement;
  declare readonly successTarget: HTMLElement;

  connect(): void {
    console.log("connect email validator");
  }

  validateEmail(): void {
    const email = this.emailTarget.value;
    const error = this.checkEmail(email);

    this.errorTarget.textContent = error;
    this.errorTarget.style.display = error ? "block" : "none";
    this.successTarget.style.display = "none";
  }

  handleSubmit(event: Event): void {
    event.preventDefault();
    const email = this.emailTarget.value;
    const error = this.checkEmail(email);

    if (error) {
      console.log(error);
      this.errorTarget.textContent = error;
      this.errorTarget.style.display = "block";
      this.successTarget.style.display = "none";
    } else {
      this.errorTarget.style.display = "none";
      this.successTarget.textContent = "メールアドレスが正常に送信されました。";
      this.successTarget.style.display = "block";
      console.log("Valid email submitted:", email);
    }
  }

  private checkEmail(email: string): string {
    if (!email) return "メールアドレスを入力してください。";
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      return "有効なメールアドレスを入力してください。";

    const [localPart, domain] = email.split("@");

    const similarDomain = commonDomains.find((d) => {
      if (d === domain) return false;

      const distance = this.levenshteinDistance(d, domain);
      const threshold = Math.max(2, Math.floor(d.length * 0.2));

      return distance <= threshold;
    });

    if (similarDomain)
      return `もしかして ${localPart}@${similarDomain} ではありませんか？`;

    return "";
  }

  private levenshteinDistance(a: string, b: string): number {
    const matrix: number[][] = Array(b.length + 1)
      .fill(null)
      .map(() => Array(a.length + 1).fill(null));

    for (let i = 0; i <= a.length; i++) matrix[0][i] = i;
    for (let j = 0; j <= b.length; j++) matrix[j][0] = j;

    for (let j = 1; j <= b.length; j++) {
      for (let i = 1; i <= a.length; i++) {
        const substitutionCost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[j][i] = Math.min(
          matrix[j][i - 1] + 1,
          matrix[j - 1][i] + 1,
          matrix[j - 1][i - 1] + substitutionCost,
        );
      }
    }

    return matrix[b.length][a.length];
  }
}
